<template>
  <div class="private-message-container">
    <!-- 返回区域 -->
    <div
      class="back-box"
      :class="{ 'bahasa-style': locale === 'bahasa-Indonesia' }"
      @click="backClick"
    >
      <i class="el-icon-arrow-left"></i>
      <span>{{ $t('GoBack') }}</span>
    </div>

    <!-- 标题区域 -->
    <div class="title-box">{{ $t('HairpinMessage') }}</div>

    <!-- 内容区域 -->
    <div class="content-box">
      <!-- 左侧 -->
      <div class="content-left">
        <div class="member-box">{{ $t('MyMember') }}</div>
        <div class="input-box">
          <div class="input-item">
            <el-input
              v-model="queryInfo.name"
              :placeholder="$t('RealName')"
              @keyup.enter.native="refreshListData"
            ></el-input>
          </div>
          <div class="input-item">
            <el-input
              v-model="queryInfo.companyName"
              :placeholder="$t('CompanyName')"
              @keyup.enter.native="refreshListData"
            ></el-input>
          </div>
        </div>
        <!-- 列表 -->
        <ul
          class="member-list"
          @scroll.passive="getScroll($event)"
        >
          <li
            v-for="item in memberList"
            :key="item.id"
            class="member-item"
          >
            <div class="left-box">
              <el-checkbox
                v-model="item.isChecked"
                @change="memberChange"
              >{{''}}</el-checkbox>
            </div>
            <div class="right-box">
              <p>{{ item.realName ? item.realName : '-' }}（{{ item.groupName }}）</p>
              <p>{{ item.realName ? item.realName : '-' }}</p>
            </div>
          </li>
        </ul>
      </div>

      <!-- 右侧 -->
      <div class="content-right">
        <!-- 表单区域 -->
        <div class="form-box">
          <el-form
            :model="form"
            :rules="formRules"
            ref="formRef"
            label-width="fit-content"
          >
            <!-- 标题 -->
            <el-form-item
              :label="$t('Title')"
              prop="title"
            >
              <el-input
                v-model="form.title"
                :placeholder="$t('InputTitle')"
                maxlength="150"
              ></el-input>
            </el-form-item>
            <!-- 内容 -->
            <el-form-item
              :label="$t('Content')"
              prop="content"
            >
              <el-input
                type="textarea"
                :rows="10"
                resize="none"
                v-model="form.content"
                :placeholder="$t('InputContent')"
                maxlength="1000"
                show-word-limit
              ></el-input>
            </el-form-item>
            <!-- 图片上传、附件上传 -->
            <div class="upload-group-box">
              <div class="upload-left">
                <!-- 图片 -->
                <el-form-item
                  :label="$t('Picture')"
                  prop="img"
                  class="img-item-box"
                >
                  <el-upload
                    ref="imgRef"
                    :action="uploadURL"
                    accept=".jpeg,.jpg,.gif,.png"
                    list-type="picture-card"
                    :auto-upload="true"
                    multiple
                    :limit="6"
                    :on-change="imgChange"
                    :on-exceed="imgExceed"
                  >
                    <i
                      slot="default"
                      class="el-icon-plus"
                    ></i>
                    <div
                      slot="file"
                      slot-scope="{file}"
                    >
                      <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url"
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-preview"
                          @click="handlePreview(file)"
                        >
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <span
                          class="el-upload-list__item-delete"
                          @click="handleRemove(file)"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </el-form-item>
              </div>
              <div class="upload-right">
                <!-- 附件 -->
                <el-form-item
                  :label="$t('Attachments')"
                  prop="attachment"
                  class="upload-item-box"
                >
                  <el-upload
                    drag
                    :action="uploadURL"
                    accept=".rar,.zip,.doc,.docx,.pdf,.jpg,.xls,.xlsx,.png,.gif"
                    :auto-upload="true"
                    multiple
                    :limit="3"
                    :on-change="attachmentChange"
                    :on-remove="attachmentRemove"
                    :on-exceed="attachmentExceed"
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">{{ $t('DragFile') }}<em>{{ $t('ClickUpload') }}</em></div>
                    <div
                      class="el-upload__tip"
                      slot="tip"
                    >{{ $t('Extension') }}: .rar .zip .doc .docx .pdf .jpg .xls .xlsx .png .gif</div>
                  </el-upload>
                </el-form-item>
              </div>
            </div>
            <!-- 已选会员 -->
            <div
              v-if="selectMember.length"
              class="select-member"
            >
              <p>{{ $t('SelectedMember') }}（{{ selectMember.length }}人）：</p>
              <p class="content">{{ selectMemberName }}</p>
            </div>
          </el-form>
          <div class="btn-box">
            <el-button
              :loading="sendLoading"
              @click="sendMessage"
            >{{ $t('Send') }}</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 图片预览 -->
    <el-dialog
      :title="$t('PicturePreview')"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      class="picture-preview"
    >
      <img
        width="100%"
        :src="dialogImageUrl"
      />
    </el-dialog>
  </div>
</template>

<script>
import mixin from "@/assets/js/mixin";
import { deepCopy } from "@/utils/tool.js";
import api from "@/fetch/api";

export default {
  name: "PrivateMessage",
  mixins: [mixin],
  data() {
    return {
      type: "send", // send:私发、edit:编辑并重新发送
      // 筛选参数
      queryInfo: {
        name: "",
        companyName: "",
        page: 1,
        pageSize: 10,
        total: 0, // 总条数
      },
      memberList: [], // 会员列表数据
      selectMember: [], // 选中会员
      selectMemberId: [], // 选中会员id
      selectMemberName: "", // 选中会员名

      // 文件上传
      uploadURL:
        "https://test.attorneyathena.com/saas-api/athena-consumer/AppUploadDO/imgUpload",
      uploadImg: [], // 上传图片
      uploadAttachment: [], // 上传附件
      // 图片预览
      dialogVisible: false,
      dialogImageUrl: "",
      sendLoading: false,

      // 表单区域
      form: {
        title: "",
        content: "",
      },
      formRules: {
        title: [
          { required: true, message: this.$t("InputTitle"), trigger: "blur" },
        ],
        content: [
          { required: true, message: this.$t("InputContent"), trigger: "blur" },
        ],
      },

      scrollTimer: null, // 节流
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.getMemberList("roll");
  },
  beforeDestroy() {
    // 清除定时器
    clearTimeout(this.scrollTimer);
    this.scrollTimer = null;
  },
  methods: {
    // 获取会员列表
    getMemberList(requestType) {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
      const params = {
        pageNum: this.queryInfo.page,
        pageSize: this.queryInfo.pageSize,
        language: languageObj[locale],
        chamberId: data.chamberId,
      };
      if (this.queryInfo.name.trim()) params.realName = this.queryInfo.name;
      if (this.queryInfo.companyName.trim())
        params.companyName = this.queryInfo.companyName;

      api.queryGroupList(params).then((res) => {
        const copyData = deepCopy(res.data.data.records);
        if (copyData.length) {
          copyData.forEach((item) => {
            item.isChecked = false;
          });
        }

        if (requestType === "filter") {
          this.memberList = [];
        }
        this.memberList = this.memberList.concat(copyData);
        this.queryInfo.total = res.data.data.total;

        if (this.type === "edit") this.dataEcho();
      });
    },

    // 数据回显
    dataEcho() {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      api
        .querySendMessageById({
          id: this.$route.query.id,
          language: languageObj[locale],
        })
        .then((res) => {
          const { title, content, visibleList } = res.data;
          this.form = {
            title,
            content,
          };
          if (this.memberList.length) {
            this.memberList.forEach((item) => {
              if (visibleList.length) {
                visibleList.forEach((vItem) => {
                  if (item.userId === vItem.groupId) item.isChecked = true;
                });
              }
            });
          }
          this.memberChange();
        });
    },

    // 会员改变
    memberChange() {
      this.selectMember = this.memberList.filter((item) => item.isChecked);
      const selectName = [];
      const selectNameId = [];
      if (this.selectMember.length) {
        this.selectMember.forEach((item) => {
          selectName.push(item.realName);
          selectNameId.push(item.userId);
        });
      }
      if (selectName.length > 20) {
        this.selectMemberName = selectName.slice(0, 20).join(",") + "...";
      } else {
        this.selectMemberName = selectName.join(",");
      }
      this.selectMemberId = selectNameId;
    },

    // 刷新列表数据
    refreshListData() {
      this.queryInfo.page = 1;
      this.getMemberList("filter");
    },

    // 返回
    backClick() {
      sessionStorage.setItem("messageCurrentIndex", 1);
      this.$router.go(-1);
    },

    // 图片状态改变
    imgChange(file, fileList) {
      const arr = [];
      if (fileList.length) {
        fileList.map((item) => {
          if (item.status === "success") arr.push(item.response.data[0]);
        });
      }
      this.uploadImg = [...arr];
    },
    // 图片文件超出
    imgExceed() {
      this.$message.warning({ message: this.$t("PictureRestrict") });
    },
    // 图片预览
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 图片删除
    handleRemove(file) {
      this.$message.success({ message: this.$t("DeleteSuccess") });
      const arr = [];
      const fileList = this.$refs.imgRef.uploadFiles;
      // 删除
      if (fileList.length) {
        fileList.map((item, index) => {
          if (item.url === file.url) fileList.splice(index, 1);
        });
      }
      // 保存已上传图片
      if (fileList.length) {
        fileList.map((item) => {
          if (item.status === "success") arr.push(item.response.data[0]);
        });
      }
      this.uploadImg = [...arr];
    },

    // 附件状态改变
    attachmentChange(file, fileList) {
      const arr = [];
      if (fileList.length) {
        fileList.map((item) => {
          if (item.status === "success") arr.push(item.response.data[0]);
        });
      }
      this.uploadAttachment = [...arr];
    },
    // 附件删除
    attachmentRemove(file, fileList) {
      this.$message.success({ message: this.$t("DeleteSuccess") });
      const arr = [];
      if (fileList.length) {
        fileList.map((item) => {
          if (item.status === "success") arr.push(item.response.data[0]);
        });
      }
      this.uploadAttachment = [...arr];
    },
    // 附件文件超出
    attachmentExceed() {
      this.$message.warning({ message: this.$t("FileRestrict") });
    },

    // 滚动请求下一页数据
    getScroll(event) {
      clearTimeout(this.scrollTimer);
      // 节流操作，防止每次一滑动就发送请求
      this.scrollTimer = setTimeout(() => {
        // 滚动条距离底部的距离scrollBottom
        const scrollBottom =
          event.target.scrollHeight -
          event.target.scrollTop -
          event.target.clientHeight;
        if (scrollBottom === 0) {
          if (
            this.queryInfo.page * this.queryInfo.pageSize >=
            this.queryInfo.total
          ) {
            return;
          }

          this.queryInfo.page++;
          this.getMemberList("roll");
        }
      }, 300);
    },

    // 发送
    sendMessage() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          // 判断是否选择会员
          if (!this.selectMember.length) {
            return this.$message.error({
              message: this.$t("PleaseSelectMember"),
              duration: 2000,
            });
          }

          this.sendLoading = true;
          const { title, content } = this.form;
          const data = JSON.parse(
            localStorage.getItem("cacheChamber")
          ).adminData;
          const params = {
            chamberId: data.chamberId,
            adminId: data.id,
            createUserId: data.id,
            updateUserId: data.id,
            messageType: 2, // 1:群发、2:私发
            isCheckAll: 0,
            title,
            content,
            imageList: "",
            attachmentList: "",
            userIds: this.selectMemberId,
          };
          if (this.uploadImg.length) {
            params.imageList = this.uploadImg.join(",");
          }
          if (this.uploadAttachment.length) {
            params.attachmentList = this.uploadAttachment.join(",");
          }

          if (this.type === "send") {
            api.sendMessageNew(params).then(() => {
              this.$message.success({
                message: this.$t("SendSuccess"),
                duration: 2000,
              });
              this.$router.push({ name: "GroupMessage" });
              this.sendLoading = false;
            });
          } else {
            params.id = this.$route.query.id;
            api.updateMessageByMessageId(params).then(() => {
              this.$message.success({
                message: this.$t("SendSuccess"),
                duration: 2000,
              });
              this.$router.push({ name: "GroupMessage" });
              this.sendLoading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.private-message-container {
  width: 1600px;
  ul,
  li {
    list-style: none;
  }

  // 返回区域
  .back-box {
    width: 70px;
    text-align: center;
    background-color: #589ef8;
    padding: 5px 0;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
  }
  .bahasa-style {
    width: 95px;
  }

  // 标题区域
  & > .title-box {
    line-height: 50px;
    text-align: center;
    background-color: #425d7b;
    margin: 20px 0;
    color: white;
    font-size: 20px;
    border-radius: 3px;
  }

  // 内容区域
  .content-box {
    display: flex;
    border: 1px solid #d7d7d7;

    // 左侧
    & > .content-left {
      width: 250px;
      border-right: 1px solid #d7d7d7;
      & > .member-box {
        line-height: 50px;
        text-align: center;
      }
      & > .input-box {
        padding: 10px 0;
        border-top: 1px solid #d7d7d7;
        border-bottom: 1px solid #d7d7d7;
        & > .input-item {
          padding: 0 10px;
          ::v-deep .el-input__inner {
            height: 30px;
            line-height: 30px;
          }
        }
        & > .input-item:first-child {
          margin-bottom: 10px;
        }
      }
      // 列表
      & > .member-list {
        height: 576px;
        overflow-y: auto;
        // 滚动条宽度为0（兼容火狐）
        scrollbar-width: none;
        // 滚动条宽度为0
        &::-webkit-scrollbar {
          display: none;
        }
        & > .member-item {
          display: flex;
          align-items: center;
          height: 64px;
          padding: 0 10px;
          border-bottom: 1px solid #d7d7d7;
          & > .right-box {
            flex: 1;
            width: 0;
            font-size: 14px;
            & > p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            & > p:first-child {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    // 右侧
    & > .content-right {
      flex: 1;
      padding: 20px;
      .form-box {
        // 图片上传、附件上传
        .upload-group-box {
          display: flex;
          & > .upload-left {
            flex: 1;
            // 图片
            .img-item-box {
              ::v-deep .el-upload--picture-card {
                border: 1px dashed #939699;
                width: 80px;
                height: 80px;
                line-height: 80px;
              }
              ::v-deep .el-upload-list--picture-card .el-upload-list__item {
                width: 82px;
                height: 82px;
                & > div {
                  height: 100%;
                }
              }
            }
          }
          & > .upload-right {
            flex: 1;
            ::v-deep .el-upload {
              .el-upload-dragger {
                width: 240px;
                height: 130px;
                border: 1px solid #939699;
              }

              .el-icon-upload {
                margin: 15px 0 0;
              }

              .el-upload__text em {
                color: #695be0;
              }
            }
          }
        }
        // 已选会员
        .select-member {
          margin-top: 20px;
        }

        .btn-box {
          margin-top: 20px;
          text-align: center;
          .el-button {
            background-color: #589ef8;
            padding: 15px 50px;
            color: #ffffff;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>